import React, { useState, useEffect } from 'react';
import { format } from "date-fns";

import { TableItemUpdate } from './TableItemUpdate';

export const TableItem = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        props.onUpdate(); // Aufruf von onUpdate hier
    };
    const handleShow = () => setShow(true);


    useEffect(() => {
        if (!show && show !== undefined) {
            // onUpdate nur dann aufrufen, wenn show explizit von 'true' auf 'false' geändert wurde
            props.onUpdate();
        }
    }, [show]);

    var entryDate = props.datum
    var formatedDate =  format(entryDate, "dd.MM.yyyy");
    

    //? Color Section

    var itemColor = "tableItem text-center"
    //! Meggi Bezahlt
    if(props.bezahltvon === 1) itemColor = "item-meggi"
    //! Gemeinsam Bezahlt
    if(props.bezahltvon === 2) itemColor = "item-zusammen"
    //! Robin Bezahlt
    if(props.bezahltvon === 3) itemColor = "item-robin"
    

    //? Kategorie Section
    
    var kategorieIcon = "bi bi-question-circle fs-2"

    //! Kategorie Einkaufen
    if (props.kategorie === 1) kategorieIcon = "bi-cart4"

    //! Kategorie Wohnung
    if (props.kategorie === 2) kategorieIcon = "bi-house-heart-fill"

    //! Kategorie Umzug
    if (props.kategorie === 3) kategorieIcon = "bi-truck"
    
    //! Kategorie Festkosten
    if (props.kategorie === 4) kategorieIcon = "bi-database-fill-check"
    
    //! Kategorie Freizeit
    if (props.kategorie === 5) kategorieIcon = "bi-balloon-heart"
    
    //! Kategorie Urlaub
    if (props.kategorie === 6) kategorieIcon = "bi-airplane"
    

    return (
        <>
        <div onClick={handleShow} className={"tableItem text-center " + itemColor}>
            <div className="row align-items-center">
                <div className="col">
                    <div className="row row-cols-1">
                        <div className="col">
                            <i className={`fs-2 bi ${kategorieIcon}`}></i>
                        </div>
                        <div className="col">
                            {formatedDate}
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="row row-cols-1">
                        <div className="col fw-bold text-dark">{props.ort}</div>
                        <div className="col fw-light text-secondary-emphasis">{props.zweck}</div>
                    </div>
                </div>
                <div className="col">
                {props.betrag}€
                </div>
            </div>
        </div>
        <TableItemUpdate
        props={props} 
        show={show} 
        handleClose={handleClose} 
        handleShow={handleShow} 
        formatedDate={formatedDate}

        />
      </>
    )
}
