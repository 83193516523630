import React from 'react';

export const Loading = (props) => {
  return (
    <div className="loadingContainer">
      <div className="loader"></div>
      {props.mess && (
        <p className='text-danger'>
          <i className="bi bi-exclamation-triangle-fill"></i> {props.mess}
        </p>
      )}
    </div>
  );
}
