import React, { useState } from 'react'
import axios from 'axios';
import { useAuth } from 'AuthContext'; // Importiere useAuth

export const TableItemAdd = () => {

  const { token } = useAuth();
  const [ort, setOrt]              = useState();
  const [zweck, setZweck]          = useState();
  const [kategorie, setKategorie]  = useState('1');
  const [datum, setDatum]          = useState();
  const [preis, setPreis]          = useState(); 
  const [bezahlt, setBezahlt]      = useState('2');
  const [alertSuccessVisible, setAlertSuccessVisible] = useState(false);
  const [alertDangerVisible, setAlertDangerVisible] = useState(false);


  const handleOptionChange = (event) => {
    setBezahlt(event.target.value);
  };

  const handleSelectChange = (event) => {
    setKategorie(event.target.value);
  }

  const handleSave = async (e) => {
    e.preventDefault();
    var betrag = Number(preis)
    var bezahltvon = Number(bezahlt)

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/einkauf`,
        {
          ort,
          kategorie,
          zweck,
          datum,
          betrag,
          bezahltvon
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      
      setOrt("");
      setZweck("");
      setKategorie("1");
      setDatum("");
      setPreis("");
      setBezahlt("2");
      setAlertSuccessVisible(true);

      setTimeout(() => {
        setAlertSuccessVisible(false);
      }, 3000);

    } catch (error) {
        console.log(`Fehler: ${error.message}`);
        setAlertDangerVisible(true)
        setTimeout(() => {
          setAlertDangerVisible(false);
        }, 6000);
    }
  };



  return (
    <div className="TableStatsContainer">
     
      <div className="inputContainer">
        {alertSuccessVisible && ( 
          <div className="alert alert-success" role="alert">
            <i className="bi bi-check-square-fill text-success"></i> Erfolgreich hinzugefügt
          </div>
        )}

        {alertDangerVisible && ( 
          <div className="alert alert-danger" role="alert">
            <i className="bi bi-exclamation-triangle text-danger"></i> Fehler
          </div>
        )}
      

        <div className="input-group mb-3 mt-3">
          <div className="input-group-text">
            <i className="bi bi-geo-alt"></i>
          </div>
          <input type="text" value={ort} onChange={(e) => setOrt(e.target.value)} className="form-control form-control-lg" placeholder="Ort" />
        </div>

        <div className="input-group mb-3">
          <div className="input-group-text">
          <i className="bi bi-person-raised-hand"></i>
          </div>
          <input type="text" value={zweck} onChange={(e) => setZweck(e.target.value)} className="form-control form-control-lg" placeholder="Zweck" />
        </div>

        <h2 className='mb1-1'>Kategorie:</h2>

        <select className="form-select form-select-lg mb-3" value={kategorie} onChange={handleSelectChange} aria-label="Default select example">
          <option value="1">Einkaufen</option>
          <option value="2">Wohnung</option>
          <option value="3">Umzug</option>
          <option value="4">Festkosten</option>
          <option value="5">Freizeit</option>
          <option value="6">Urlaub</option>
        </select>
        

        <h2 className='mb1-1'>Datum:</h2>

        <div className="input-group mb-3">
          <div className="input-group-text">
            <i className="bi bi-calendar-heart"></i>
          </div>
          <input type="date" value={datum} onChange={(e) => setDatum(e.target.value)} className="form-control form-control-lg" placeholder="Date" />
        </div>

        <h2 className='mb1-1'>Preis:</h2>

        <div className="input-group mb-3">
          <div className="input-group-text">
            <i className="bi bi-currency-euro"></i>
          </div>
          <input type="number" value={preis} onChange={(e) => setPreis(e.target.value)} className="form-control form-control-lg" placeholder="Preis" />
        </div>

        <h2 className='mb1-1'>Bezahlt von:</h2>

        <div className="radioSelection mb-3">
          <div className="radioItemContainer">
            <input className="radioItem" type="radio" value="1" checked={bezahlt === '1'} onChange={handleOptionChange} name="bezahltRadios"/>
            <label>Meggi</label>
          </div>
          <div className="radioItemContainer">
            <input className="radioItem" type="radio" value="2" checked={bezahlt === '2'} onChange={handleOptionChange} name="bezahltRadios"/>
            <label>Zusammen</label>
          </div>
          <div className="radioItemContainer">
            <input className="radioItem" type="radio" value="3" checked={bezahlt === '3'} onChange={handleOptionChange} name="bezahltRadios"/>
            <label>Robin</label>
          </div>
        </div>

        <button className="btn btn-lg customBtn" onClick={handleSave} type="submit">
          <i className="bi bi-cloud-plus fs-3"></i>
        </button>

      </div>
    </div>
  )
}
