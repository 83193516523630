import React, { useState, useEffect } from 'react';
import './App.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { TableContainer } from './NewComponents/TableContainer';
import { TableStats } from './NewComponents/TableStats';
import { TableSettings } from './NewComponents/TableSettings';
import { TopNav } from './NewComponents/TopNav';
import { BottomNav } from './NewComponents/BottomNav';
import { TableItemAdd } from './NewComponents/TableItemAdd';
import axios from 'axios';

import { useAuth } from './AuthContext'; // Importiere useAuth

function App() {

  

  const { token, setToken } = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  const [inputPassword, setInputPassword] = useState('');


  useEffect(() => {
    setIsAuthenticated(!!token);
  }, [token]);

  const handlePasswordChange = (e) => {
    setInputPassword(e.target.value);
  };

  const handlePasswordSubmit = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, { password: inputPassword });
      setToken(response.data.token); // Setze das Token im Context
    } catch (error) {
      // Überprüfe, ob die Fehlerantwort vorhanden ist
      const errorMessage = error.response 
        ? error.response.data.message || "Unbekannter Fehler"
        : "Netzwerkfehler oder Server nicht erreichbar";
        
      alert("Fehler beim Login: " + errorMessage);
    }
  };
  

  const handleLogout = () => {
    setToken(''); // Setze das Token auf leer
  };

  return (
    <Router>
      
      <div >
        {!isAuthenticated ? (
          <div className="login-container">
          <div className="password-container">
            <div className="input-group mb-3">
              <div className="input-group-text">
                <i className="bi bi-key"></i>
              </div>
            <input 
            type="password"
            value={inputPassword} 
            onChange={handlePasswordChange}
            className="form-control form-control-lg" 
            placeholder="Passwort eingeben"
            />
            </div>
            <button className="btn btn-lg customBtn" onClick={handlePasswordSubmit} type="submit">
              <i className="bi bi-door-open fs-3"></i>
            </button>
          </div>
          </div>
        ) : (
          <div className='container text-center'>
            <TopNav />
            <Routes>
              <Route exact path="/" element={<TableContainer />} />
              <Route path="/stats" element={<TableStats />} />
              <Route path="/settings" element={<TableSettings />} />
              <Route path="/add" element={<TableItemAdd />} />
            </Routes>
            <BottomNav onLogoutClick={handleLogout} />
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;
