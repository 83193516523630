import React from 'react'

import { NavLink } from 'react-router-dom';

export const BottomNav = ({onLogoutClick}) => {

   
  return (
    <div className='fixed-bottom d-flex justify-content-center custom-bottom-nav pb-5'>
        <div className="row">
            <div className="col p-1">
                <NavLink to="/" className={({ isActive }) => isActive ? 'active-link' : 'nlink'}>
                    <i className=" bi bi-list-task"></i>
                </NavLink>
            </div>
            <div className="col p-1">
                <NavLink to="/stats" className={({ isActive }) => isActive ? 'active-link' : 'nlink'}>
                    <i className=" bi bi-graph-up"></i>
                </NavLink>
            </div>
            <div className="col p-1">
                <NavLink to="/add" className={({ isActive }) => isActive ? 'active-link' : 'nlink'}>
                    <i className="bi bi-plus-circle"></i>
                </NavLink>
            </div>
            <div className="col p-1">
                <NavLink to="/settings" className={({ isActive }) => isActive ? 'active-link' : 'nlink'}>
                    <i className=" bi bi-gear"></i>
                </NavLink>
            </div>
            <div className="nlink col p-1">
                <i className=" bi bi-box-arrow-in-right" onClick={onLogoutClick}></i>
            </div>
        </div>
    </div>
  )
}
