import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TableItem } from '../NewComponents/TableItem';
import { TableItemMonthInfo } from './TableItemMonthInfo';
import { Loading } from './Loading';
import { useAuth } from '../AuthContext'; // Importiere useAuth

// Helper function to get the last day of a month
const getLastDayOfMonth = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  return new Date(year, month + 1, 0); // Last day of the month
};

// Function to format month
const getFormattedMonth = (date) => {
  return new Date(date).toLocaleString('de-DE', { year: 'numeric', month: 'long' });
};

export const TableContainer = () => {
  const { token } = useAuth(); // Hole den Token aus dem Context
  const [tableItems, setTableItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch data from the server
  const fetchEinkauf = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/einkauf`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setTableItems(response.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEinkauf();
  }, []);

  const onUpdate = () => {
    fetchEinkauf();
  }

  // Sort items in descending order by date
  const sortedItems = [...tableItems].sort((a, b) => new Date(b.datum) - new Date(a.datum));

  // Group items by month
  const groupedItems = sortedItems.reduce((acc, item) => {
    const date = new Date(item.datum);
    const yearMonth = `${date.getFullYear()}-${date.getMonth() + 1}`;
    if (!acc[yearMonth]) {
      acc[yearMonth] = [];
    }
    acc[yearMonth].push(item);
    return acc;
  }, {});

  const monthKeys = Object.keys(groupedItems);


  if (loading) return <Loading/>;
  if (error) return <Loading mess={error.message}/>;
  return (
    <div className="TableItemContainer">
      {monthKeys.map((monthKey, index) => {
        const items = groupedItems[monthKey];
        // Find the last item of the current month
        const lastItem = items[items.length - 1];
        const lastDayOfMonth = getLastDayOfMonth(new Date(lastItem.datum));
        const lastDayOfMonthString = lastDayOfMonth.toISOString().substring(0, 10);

        return (
          <React.Fragment key={monthKey}>
            {/* Render TableItemMonthInfo only once per month, at the end of the month */}
            <TableItemMonthInfo month={getFormattedMonth(lastItem.datum)} />
            
            {/* Render items for this month */}
            {items.map((tableItem) => (
              <TableItem
                key={tableItem.ID}
                id={tableItem.ID}
                datum={tableItem.datum}
                ort={tableItem.ort}
                kategorie={tableItem.kategorie}
                betrag={tableItem.betrag}
                bezahltvon={tableItem.bezahltvon}
                zweck={tableItem.zweck}
                onUpdate={onUpdate}
              />
            ))}
          </React.Fragment>
        );
      })}
      
    </div>
  );
};
