import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Loading } from './Loading';
import { useAuth } from 'AuthContext';

const categoryMapping = {
    1: 'Einkaufen', // 
    2: 'Wohnung',
    3: 'Umzug',
    4: 'Festkosten',
    5: 'Freizeit',
    6: 'Urlaub'
};

const monthNamesGerman = {
    january: 'Januar',
    february: 'Februar',
    march: 'März',
    april: 'April',
    may: 'Mai',
    june: 'Juni',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'Dezember'
};

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const total = payload.reduce((sum, current) => sum + (current.value || 0), 0);
        const month = monthNamesGerman[label.toLowerCase()]; // Translate month to German

        
        const remaining = 1400 - total.toFixed(2);
        return (
            <div className="custom-tooltip">
                <p className="label">{`${month}`}</p>
                {payload.map((entry, index) => (
                    <p key={`item-${index}`} style={{ color: entry.color }}>{`${entry.name}: ${entry.value.toFixed(2)}€`}</p>
                ))}
                <p className="total" style={{ color: '#000' }}>{`Gesamtsumme: ${total.toFixed(2)}€`}</p>
                <p className="total" style={{ color: '#000' }}>{`Budget: ${
                    remaining.toFixed(2)
                    }€`}</p>
            </div>
        );
    }
    return null;
};

const yAxisTickFormatter = (value) => `${value}€`;

const xAxisTickFormatter = (value) => {
    const lowerCaseMonth = value.toLowerCase();
    return monthNamesGerman[lowerCaseMonth];
};

export const TableStats = () => {
    const { token } = useAuth();

    const [barChartData, setBarChartData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchBarChartData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/insgesamtsumme/alle`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const rawData = response.data;
            const formattedData = Object.keys(rawData).map(month => {
                const data = { month };
                rawData[month].forEach(item => {
                    const categoryName = categoryMapping[item.kategorie];
                    data[categoryName] = item.money;
                });
                return data;
            });
            setBarChartData(formattedData);
            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBarChartData();
    }, []);

    if (loading) return <Loading/>;
    if (error) return <Loading mess={error.message}/>;
    //if (error) return <p>Error: {error.message}</p>;

    return (

    <div className="TableStatsContainer">
        <ResponsiveContainer width="100%" height={400}>
            <BarChart
                data={barChartData}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" tickFormatter={xAxisTickFormatter} />
                <YAxis tickFormatter={yAxisTickFormatter} />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Bar dataKey="Einkaufen" stackId="a" fill="#64A6BD" name="Einkaufen" />
                <Bar dataKey="Wohnung" stackId="a" fill="#F4CAE0" name="Wohnung" />
                <Bar dataKey="Umzug" stackId="a" fill="#94C9A9" name="Umzug" />
                <Bar dataKey="Festkosten" stackId="a" fill="#ADA7C9" name="Festkosten" />
                <Bar dataKey="Freizeit" stackId="a" fill="#D7B9D5" name="Freizeit" />
                <Bar dataKey="Urlaub" stackId="a" fill="#90A8C3" name="Urlaub" />
            </BarChart>
        </ResponsiveContainer>
    </div>
    );
};
