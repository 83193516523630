import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';

import { useAuth } from 'AuthContext'; // Importiere useAuth


export const TableItemUpdate = ({ props, show, handleClose, handleShow }) => {

    const { token } = useAuth();

    const [id, setID] = useState('')
    const [ort, setOrt] = useState('');
    const [kategorie, setKategorie] = useState('')
    const [zweck, setZweck] = useState('')
    const [datum, setDatum] = useState('')
    const [betrag, setBetrag] = useState('')
    const [bezahltvon, setBezahltvon] = useState('')

    
    const dateString = props.datum;

    // Erstelle ein Date-Objekt aus dem String
    const date = new Date(dateString);

    

    // Einen Tag hinzufügen
    date.setDate(date.getDate() + 1);

    // Formatieren des neuen Datums
    const newFormattedDate = date.toISOString().split('T')[0];
   //console.log("Neues Datum nach Hinzufügen eines Tages:", newFormattedDate); // "2024-09-01"

   const formatedPayedFrom = Number(props.bezahltvon)


    //! Einmaliges Laden der Daten beim rendern der Funktion
    useEffect(() => {
        setID(props.id);
        setOrt(props.ort);
        setKategorie(props.kategorie);
        setZweck(props.zweck)
        setDatum(newFormattedDate)
        setBetrag(props.betrag)
        setBezahltvon(formatedPayedFrom)
    }, [
        props.id,
        props.ort,
        props.kategorie,
        props.zweck,
        newFormattedDate,
        props.betrag,
        formatedPayedFrom,
    ]);

    const handleOptionChange = (event) => {
        setBezahltvon(event.target.value);
    };

    const handleSelectChange = (event) => {
    setKategorie(event.target.value);
    }

    const handleUpdate = () => {
        handleUpdateData()
        handleClose()  
    }

    
    const handleUpdateData = async (e) => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/update`,
            {
              id,
              ort,
              kategorie,
              zweck,
              datum,
              betrag,
              bezahltvon
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          );
        } catch (error) {
            console.log(`Fehler: ${error.message}`);
        }
    };

    const handleDelete = () => {
      handleDeleteData()
      handleClose()  
    }

    const handleDeleteData = async (e) => {
      try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/delete`, { 
              id
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
          }

        });
          console.log(response)
      } catch (error) {
          console.log(`Fehler: ${error.message}`);
      }
  };

    

    return (
        
        <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        >
        <Modal.Header closeButton>
        <Modal.Title>
            <i className="bi bi-pen"></i>
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="input-group mb-3 mt-3">
            <div className="input-group-text">
                <i className="bi bi-geo-alt"></i>
            </div>
            <input 
                type="text" 
                value={ort} 
                className="form-control form-control-lg"  
                onChange={(e) => setOrt(e.target.value)} 
                placeholder="Ort" 
            />
        </div>

        <div className="input-group mb-3">
            <div className="input-group-text">
                <i className="bi bi-person-raised-hand"></i>
            </div>
            <input 
                type="text"
                value={zweck} 
                onChange={(e) => setZweck(e.target.value)}
                className="form-control form-control-lg" 
                placeholder="Zweck"
            />
        </div>

        <h2 className='mb1-1'>Kategorie:</h2>

        <select className="form-select form-select-lg mb-3" value={kategorie} onChange={handleSelectChange} aria-label="Default select example">
            <option value="1">Einkaufen</option>
            <option value="2">Wohnung</option>
            <option value="3">Umzug</option>
            <option value="4">Festkosten</option>
            <option value="5">Freizeit</option>
            <option value="6">Urlaub</option>
        </select>

        <h2 className='mb1-1'>Datum:</h2>

        <div className="input-group mb-3">
          <div className="input-group-text">
            <i className="bi bi-calendar-heart"></i>
          </div>
          <input type="date" value={datum} onChange={(e) => setDatum(e.target.value)} className="form-control form-control-lg" placeholder="Date" />
        </div>

        <h2 className='mb1-1'>Preis:</h2>

        <div className="input-group mb-3">
          <div className="input-group-text">
            <i className="bi bi-currency-euro"></i>
          </div>
          <input type="number" value={betrag} onChange={(e) => setBetrag(e.target.value)} className="form-control form-control-lg" placeholder="Betrag" />
        </div>


        <h2 className='mb1-1'>Bezahlt von:</h2>

        <div className="radioSelection mb-3">
          <div className="radioItemContainer">
            <input className="radioItem" type="radio" value="1" checked={bezahltvon === '1'} onChange={handleOptionChange} name="bezahltRadios"/>
            <label>Meggi</label>
          </div>
          <div className="radioItemContainer">
            <input className="radioItem" type="radio" value="2" checked={bezahltvon === '2'} onChange={handleOptionChange} name="bezahltRadios"/>
            <label>Zusammen</label>
          </div>
          <div className="radioItemContainer">
            <input className="radioItem" type="radio" value="3" checked={bezahltvon === '3'} onChange={handleOptionChange} name="bezahltRadios"/>
            <label>Robin</label>
          </div>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <button size="lg" variant="primary" className='btn btn-lg customBtn2' onClick={handleDelete}>
          <i className="p-2 bi bi-trash3"></i>
          </button>
          <button size="lg" variant="primary" className='btn btn-lg customBtn' onClick={handleUpdate}>
          <i className="p-4 bi bi-floppy"></i>
          </button>
        </Modal.Footer>
      </Modal>
    );
}
